<template>
  <v-navigation-drawer
    class="d-print-none"
    v-model="drawer"
    :color="color"
    :expand-on-hover="expandOnHover"
    :mini-variant="miniVariant"
    :right="right"
    :permanent="permanent"
    dark
    app
    v-bind:width="250"
  >
    <div id="navigation-drawer-text">Rezervační systém</div>
    <v-divider></v-divider>
    <v-list
      dense
      nav
      class="py-0"
    >
      <div
        v-for="(item, index) in items"
        :key="index">
        <v-list-item
          link
          :to="{name: `admin.${item.linkName}`}"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
      <v-list-group
        prepend-icon="mdi-archive"
      >
        <template v-slot:activator>
          <v-list-item-title>Archivy</v-list-item-title>
        </template>
        <v-list-item
          v-for="(dropdownItem, index) in dropdownItems"
          :key="index"
          link
          :to="{name: `admin.${dropdownItem.linkName}`}"
        >
          <v-list-item-title>{{ dropdownItem.title }}</v-list-item-title>

          <v-list-item-icon>
            <v-icon>{{ dropdownItem.icon }}</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <v-btn block light
          @click="logout"
        >Odhlásit se</v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'NavigationDrawer',
  data () {
    return {
      drawer: true,
      items: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard', linkName: 'dashboard' },
        { title: 'Uživatelé', icon: 'mdi-account', linkName: 'users' },
        { title: 'Hostitelé', icon: 'mdi-home-account', linkName: 'hosts' },
        { title: 'Karty objektů', icon: 'mdi-format-list-text', linkName: 'properties' },
        { title: 'Poptávky', icon: 'mdi-card-text', linkName: 'demands' },
        { title: 'Rezervace', icon: 'mdi-card-bulleted-outline', linkName: 'reservations' },
        { title: 'Nahlášení', icon: 'mdi-bullhorn', linkName: 'reports' },
        { title: 'Zpětná vazba', icon: 'mdi-comment-quote', linkName: 'feedback' },
        { title: 'Nastavení topování', icon: 'mdi-finance', linkName: 'promo' },
        { title: 'Kupóny', icon: 'mdi-ticket-percent', linkName: 'coupons' },
        { title: 'Emailing', icon: 'mdi-email-newsletter', linkName: 'emailing' },
        { title: 'Nastavení', icon: 'mdi-cogs', linkName: 'settings' }
      ],
      dropdownItems: [
        {
          title: 'Uživatelů',
          icon: 'mdi-account',
          linkName: 'users-archive'
        },
        {
          title: 'Hostitelů',
          icon: 'mdi-home-account',
          linkName: 'hosts-archive'
        },
        {
          title: 'Karet objektů',
          icon: 'mdi-format-list-text',
          linkName: 'properties-archive'
        },
        {
          title: 'Poptávek',
          icon: 'mdi-card-text',
          linkName: 'demands-archive'
        },
        {
          title: 'Rezervací',
          icon: 'mdi-card-bulleted-outline',
          linkName: 'reservations-archive'
        }
      ],
      color: 'blue darken-2',
      colors: [
        'primary',
        'blue',
        'success',
        'red',
        'teal'
      ],
      right: false,
      permanent: true,
      miniVariant: false,
      expandOnHover: false,
      background: false
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('userAuth/logout')
    }
  }
}
</script>

<style scoped>
#navigation-drawer-text{
  text-align: center;
  color: #fffefe;
  padding: 1.5rem;
  font-size: 1.2em;
}
>>>.v-list-item--active{
  color: #fffefe !important;
}
.v-list-item__title{
  font-size: 0.9em !important;
}
.v-navigation-drawer{
  width: 250px !important;
}
</style>
