<template>
  <div>
    <v-app-bar
      elevation="1"
      app
      color="white"
      dark
    >
      <v-breadcrumbs
        :items="breadcrumbItems"
      ></v-breadcrumbs>
      <v-spacer></v-spacer>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  computed: {
    breadcrumbItems () {
      return this.$route.matched.map((route, index) => {
        return {
          href: route.path,
          text: route.meta.title,
          disabled: index === this.$route.matched.length - 1
        }
      })
    }
  }
}
</script>

<style scoped>
.v-list-item{
  padding: 0;
}
>>>.v-breadcrumbs__item--disabled {
  color: grey !important;
}
>>>.v-breadcrumbs__divider{
  color: black !important;
}
</style>
