<template>
  <div>
    <v-alert
      v-for="alert in permanentAlerts"
      :key="alert.id"
      :type="alert.type"
      class="mb-0 rounded-0"
      dismissible
    >
      {{ alert.text }}
    </v-alert>
    <v-alert
      v-for="(alert, index) in alerts"
      :key="index"
      :type="alert.type"
      class="mb-0 rounded-0"
    >
      {{ alert.message }}
    </v-alert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AlertPanel',
  computed: {
    ...mapGetters({
      alerts: 'alerts/alerts',
      permanentAlerts: 'alerts/permanentAlerts'
    })
  }
}
</script>

<style scoped>

</style>
